<template>
  <div class="flex flex-col overflow-y-auto w-full">
    <p class="font-bold text-darkblue text-xl font-SourceSansPro">
      {{ notification.type.value }}
    </p>
    <div class="flex mt-3 relative justify-between">
      <!-- <div class="flex flex-col">
        <p
          class="text-annotationColor font-SourceSansPro text-base font-semibold"
        >
          {{ $t('notifications.sensor.type') }}
        </p>
        <el-select
          class="mt-2"
          :disabled='!notRole && role !== "EDITOR"'
          v-model="notification.sensor_type"
          clearable="clearable"
          :class="{ 'input-error': validateOne }"
        >
          <el-option
            v-for="item in sensor_type"
            :key="item.key"
            :label="item.value"
            :value="item"
          ></el-option>
        </el-select>
      </div> -->
      <div class="flex flex-col w-full">
        <p
          class="text-annotationColor font-SourceSansPro text-base font-semibold"
        >
          {{ $t('notifications.sensor.maskByName') }}
        </p>
        <el-input
          class="w-full mt-2"
          :disabled="!notRole && role !== 'EDITOR'"
          v-model="notification.name_mask"
          :class="{ 'input-error': validateOne }"
        />
      </div>
      <transition name="el-zoom-in-top">
        <div
          class="text-notify text-xs top-full left-0 absolute"
          v-if="validateOne"
        >
          {{ $t('notifications.sensor.warning') }}
        </div>
      </transition>
    </div>
    <div class="flex mt-5 relative justify-between">
      <div class="flex flex-col mr-4">
        <p
          class="text-annotationColor font-SourceSansPro text-base font-semibold mb-2"
        >
          {{ $t('notifications.sensor.meaningFrom') }}
        </p>
        <skif-input-number
          class="w-full"
          :disabled="!notRole && role !== 'EDITOR'"
          v-model.number="notification.sensor_notless"
        />
      </div>
      <div class="flex flex-col">
        <p
          class="text-annotationColor font-SourceSansPro text-base font-semibold mb-2"
        >
          {{ $t('notifications.sensor.meaningTo') }}
        </p>
        <skif-input-number
          class="w-full"
          :disabled="!notRole && role !== 'EDITOR'"
          v-model.number="notification.sensor_notmore"
        />
      </div>
    </div>
    <div
      v-if="notification.type.key === 'draining_notification'"
      class="mt-5 relative"
    >
      <p
        class="text-annotationColor font-SourceSansPro text-base font-semibold mb-2"
      >
        {{ $t('notifications.sensor.minimalTime') }}
      </p>
      <div class="flex w-full">
        <el-input
          class="w-full mr-4"
          :placeholder="$t('notifications.sensor.writeNumber')"
          :disabled="!notRole && role !== 'EDITOR'"
          @change="$emit('change-min-value-time', minValueTime)"
          v-model.number="minValueTime"
        />
        <el-select
          :disabled="!notRole && role !== 'EDITOR'"
          v-model="notification.trigger_duration_type"
        >
          <el-option
            v-for="item in triggerTypes"
            :key="item.key"
            :label="item.value"
            :value="item"
          />
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    isIntervalMissing: {
      type: Boolean,
      default: false
    },
    isSensorMoreLess: {
      type: Boolean,
      default: false
    },
    isSensorTypeOrMask: {
      type: Boolean,
      default: false
    },
    notification: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      params: [
        { key: true, value: 'В установленных рамках' },
        { key: false, value: 'Вне установленных рамок' }
      ],
      minValueTime: 0
    }
  },
  computed: {
    ...mapState({ sensor_type: (state) => state.dictionary.sensor_type }),
    validateOne: {
      get() {
        return this.isSensorTypeOrMask
      },
      set(newValue) {
        this.$emit('updateSensorType', newValue)
      }
    },
    ...mapGetters('login', ['role', 'notRole']),
    validateTwo: {
      get() {
        return this.isSensorMoreLess
      },
      set(newValue) {
        this.$emit('updateSensorMoreLess', newValue)
      }
    },
    validateThree: {
      get() {
        return this.isIntervalMissing
      },
      set(newValue) {
        this.$emit('updateInterval', newValue)
      }
    },
    ...mapGetters({
      triggerTypes: 'dictionary/triggerDuration'
    })
  },
  watch: {
    'notification.sensor_type': function (val) {
      this.validateOne =
        !Object.keys(val).length && this.notification.name_mask === ''
    },
    'notification.name_mask': function (val) {
      this.validateOne =
        val === '' && !Object.keys(this.notification.sensor_type).length
    },
    'notification.in_interval': function (val) {
      this.validateThree = val === ''
    },
    'notification.sensor_notless': function (val) {
      this.validateTwo = val === '' && this.notification.sensor_notmore === ''
    },
    'notification.sensor_notmore': function (val) {
      this.validateTwo = val === '' && this.notification.sensor_notless === ''
    }
  },
  mounted() {
    this.minValueTime = this.notification.min_trigger_duration_value
  }

  // methods: {
  //   sensorTypeClear() {
  //     this.notification.sensor_type = {}
  //   },
  // },
}
</script>
